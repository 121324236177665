<template>
  <div class="row">
    <div class="col-sm-5">
      <b-card>
        <baseform
          :fields="fields"
          :posturl="dataurl"
          :title="title"
          :data-detail="details"
        />
      </b-card>
    </div>
    <div class="col-sm-7">
      <b-card>
      
        <validation-observer ref="simpleRules">
          <b-form>
            <h4 class="mr-1 mt-2">
              Item
            </h4>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-cols-md="4"
                  label="Tipe Kegiatan"
                  label-for="activity_type"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Activity"
                    rules="required"
                  >
                    <b-form-select
                      v-model="activity_type"
                      :options="enumActivity"
                      value-field="key"
                      text-field="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="4"
                  label="Harga Satuan"
                  label-for="cost"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cost"
                    rules="required"
                  >
                    <b-form-input
                      v-model="cost"
                      :plaintext="false"
                      placeholder="Inputkan Cost"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="4"
                  label="QTY"
                  label-for="qty"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Qty"
                    rules="required"
                  >
                    <b-form-input
                      v-model="qty"
                      type="number"
                      :plaintext="false"
                      placeholder="Inputkan Qty"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="4"
                  label="Kode Barang"
                  label-for="item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Item"
                    rules="required"
                  >
                    <baseSelect
                      v-model="item"
                      :dataurl="'/maintenance-item'"
                      title="item"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  class="mr-1"
                  variant="warning"
                  type="button"
                  @click.prevent="reset"
                >
                  <span>Reset</span>
                </b-button>
                <b-button
                  class="mr-1"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                
                  <span>Tambah</span>
                </b-button>
              
              </b-col>
              <br>
            </b-row>
          </b-form>
        </validation-observer>
        <br>
        <b-table
          striped
          responsive
          :items="details"
          :fields="table_details"
        >
          <template #cell(_)="data">
            <span
              class="mr-1"
            >
              <b-link @click="confirmDelete(data)">
                <b-badge variant="danger">
                  <feather-icon icon="TrashIcon" />
                </b-badge>
              </b-link>
            </span>
          </template>
          <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BForm, BRow, BCol, BTable, BLink, BBadge, BFormGroup, BFormSelect, BFormInput, BButton} from 'bootstrap-vue'
import baseSelect from '@/views/base/BaseSelect.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    baseform,
    BForm,
    BCard,
    BTable,
    BLink,
    BBadge, 
    BFormGroup,
    BFormSelect,
    BFormInput,
    baseSelect,
    BButton,
    BRow, 
    BCol
  },
  data() {
    return {
      dataurl:"/maintenance",
      baseroute:"maintenance",
      title:"Perawatan",
      fields : [
        { key: 'code', label: 'Kode Perawatan', type: 'input', rules:'required' },
        { key: 'is_external', label: 'Perawatan Eksternal', type: 'bool'},
        { key: 'name', label: 'Nama Perawatan', type: 'input', rules:'required' },
        { key: 'plan_km', label: 'KM Perawatan', type: 'number', rules:'required' },
        { key: 'plan_date', label: 'Tanggal Rencana', type: 'date', rules:'required' },
        { key: 'plan_cost', label: 'Biaya Perencanaan', type: 'number', rules:'required' },
        { key: 'plan_service_cost', label: 'Biaya Layanan Pemasangan', type: 'number', rules:'required' },
        { key: 'description', label: 'Deskripsi', type: 'input', rules:'required' },
        { key: 'vehicle', label: 'Kendaraan'  , type: 'select-ajax', rules:'required', dataurl:'/vehicle', title:'Kendaraan', dataFields: [{ key: 'number', label: 'Nopol' }] },
        { key: 'workshop', label: 'Tempat Perawatan', type: 'select2', rules:'required', dataurl:'/workshop', title:'Bengkel' },
        { key: 'details', type: 'details'}
      ],
      table_details:[
        { key: 'activity', label: 'Activity'},
        { key: 'cost', label: 'Cost' },
        { key: 'qty', label: 'Qty' },
        { key: 'item', label: 'Item'},
        '_'
      ],
      details:[],
      enumActivity:[
        {key: 'periksa', name: 'Pemeriksaan'},
        {key: 'ganti', name: 'Penggantian'},
        {key: 'tambah', name: 'Penambahan'}
      ],
      activity_type:"",
      cost:"",
      qty:"",
      item:{},
      dataItem:{}
    }
  },
  beforeMount(){
    if(this.$route.params.id != null){
      this.$http.get(this.dataurl+'/'+this.$route.params.id).then(res=>{
        for(var index in res.data.details){
          this.details.push(
              {
                activity_type : res.data.details[index].activity_type,
                activity: this.enumActivity.find(x => x.key == res.data.details[index].activity_type).name,
                cost: res.data.details[index].cost, 
                qty: res.data.details[index].qty, 
                item: res.data.details[index].item.name,
                item_id: res.data.details[index].item_id
              })
        }
      })
    }
  },
  methods: {
    confirmDelete(data){
      this.details.splice(data.index, 1)
    },
    reset(){
      this.cost = ""
      this.qty = ""
      this.item = ""
    },
    validationForm() {
      this.$refs.simpleRules.validate().then( res => {
        if(res){
          this.details.push(
            {
              activity_type : this.activity_type,
              activity: this.enumActivity.find(x => x.key == this.activity_type).name,
              cost: this.cost, 
              qty: this.qty, 
              item: this.item.name,
              item_id: this.item.id
            })
            this.reset()
            this.$refs.simpleRules.reset()
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    }
  }
}
</script>